@import '../../styles/variables.scss';

.container {
  display: flex;
  padding: $mediumSpace;
  color: $otcBlack;
  font-size: 12px;
  font-weight: $fontWeightExtraBold;
  border: $otcGray 1px solid;
  border-radius: 8px;
  cursor: pointer;

  &.active {
    background-color: $otcGreen;
    color: $otcWhite;
  }

  &:hover {
    @include btnHover;
  }
}

.errorContainer {
  display: inline-block;
  font-size: 13px;
  font-weight: $fontWeightRegular;
  color: $otcErrorColor;
  margin-left: $smallSpace
}