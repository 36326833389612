@import '../../styles/variables.scss';

.container {
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap: $mediumSpace;

  &.oneButton {
    grid-template-columns: 1fr;
  }
}