@import '../../styles/variables.scss';

.container {
  display: inline-block;
  &.small {
    font-size: 12px;
    font-weight: $fontWeightBold;
  }
  
  &.big {
    @include h2;
    margin: 0 0 $smallSpace;
  }

  .icon {
    padding-right: $xsmallSpace;

    &.green {
      color: $otcGreen;
    }

    &.moreInfo {
      width: $moreInfoIconSize;
      cursor: pointer;
      padding-left: $xsmallSpace;
    }
  }
}