@import '../../styles/variables.scss';

.container {
    width: 700px;
    padding-top: $largeSpace;
    margin: 0 auto;

    .title {
        color: $otcGreen;
    }

    .cta {
        font-size: 16px;
    }
}