@import '../../styles/variables.scss';

.h1 {
  @include h1;
  margin: 0 0 $smallSpace;
}

.h2 {
  @include h2;
  margin: 0 0 $smallSpace;
}

.h3 {
  @include h3;
  margin: 0 0 $smallSpace;
}

.noMargin {
  margin: 0;
}

.error {
  color: $otcErrorColor;
}