@import '../../styles/variables.scss';

.container {
  position: fixed;
  bottom: $mediumSpace;
  left: $mediumSpace;
  z-index: 1000000;

  .notification {
    &:not(:last-child) {
      margin-bottom: $mediumSpace;
    }
  }
}