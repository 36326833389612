@import '../../styles//variables.scss';

.container {
  width: 100%;
  border-spacing: 0 $smallSpace;
  border-collapse: separate;

  .cell {
    border-top: 1px solid $otcGray;
    border-bottom: 1px solid $otcGray;
    padding: $smallSpace $mediumSpace;
    margin-bottom: $smallSpace;
    text-align: left;

    &:first-child {
      border-left: 1px solid $otcGray;
    }

    &:last-child {
      border-right: 1px solid $otcGray;
    }
  }

  .dialogCell {
    position: absolute;
    width: 100%;
    right: 0;
  }

  tr, td {
    position: relative;
  }

  .moreInfo {
    width: $moreInfoIconSize;
    padding-left: $xsmallSpace;
    vertical-align: top;
    cursor: pointer;
  }

  .rowClick {
    cursor: pointer;
  }

  .dialog {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
  }
}