@import '../../styles/variables.scss';

.container {
  display: flex;
  height: 48px;
  padding: $mediumSpace;
  color: $otcBlack;
  font-size: 16px;
  font-weight: $fontWeightRegular;
  border: $otcGray 1px solid;

  &:hover {
    background-color: $otcGreen;
    color: $otcWhite;
    border-color: $otcGreen;
  }

  &.secondary {
    border-radius: 8px;
  }

  &.spaceBetween {
    justify-content: space-between;
  }

  &.blackBorder {
    border-color: $otcBlack;
    &:hover {
      border-color: $otcGreen;
    }
  }
 
  .icon {
    margin-right: 10px;
  }

  &.active {
    font-weight: $fontWeightSemiBold;
    background-color: $otcGreen;
    color: $otcWhite;
    border: none;
  }

  .back {
    font-weight: $fontWeightBold;
    font-size: 14px;
  }
}