@import '../../styles/variables.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;

  &.background {
    height: 100%;
    width: 100%;
    z-index: 999;
    animation: fadeInColor .3s forwards;
  }  

  .slideContainer {
    display: block;
    width: 720px;
    height: calc(100vh - #{$headerHeight});
    background-color: $otcWhite;
    overflow-y: scroll;
    float: right;
    box-shadow: -1px 2px 7px 0 rgba(0,0,0,0.25);
  
    .header {
      position: sticky;
      top: 0;
      background-color: $otcWhite;
      z-index: 10000;
      padding-top: $XLargeSpace;
      margin-bottom: $XLargeSpace;
  
      .headerContent {
        margin: 0 104px;
      }
  
      .titleRow {
        display: flex;
        justify-content: space-between;
        padding-bottom: $smallSpace;
        border-bottom: 1px solid $otcGray;
  
        .closeBtn {
          cursor: pointer;
          font-size: 34px;
          color: #6D7278;
        }
      }
  
      .subTitle {
        font-size: 14px;
        line-height: 16px;
        font-style: italic;
        border-bottom: 1px solid $otcGray;
        padding-bottom: $smallSpace;
      }
    }
  
    .content {
      padding: 0 104px $XXLargeSpace;
    }
  }  
}

@keyframes fadeInColor {
  from {background-color: rgba(0,0,0, 0);}
  to {background-color: rgba(0,0,0, .1);}
}
