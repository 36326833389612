@import '../../styles/variables.scss';

.slide-out {
  position: fixed;
  top: $headerHeight;
  width: 100%;
  z-index: 1000;
}

.panel-enter-done {
  right: 0;
}

.panel-enter {
  right: -720px;
}
.panel-enter-active {
  right: 0;
  transition: right 300ms;
}
.panel-exit {
  right: 0;
}
.panel-exit-active {
  right: -720px;
  transition: right 300ms;
}
