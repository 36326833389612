@import '../../../styles/variables.scss';

.title {
    display: flex;
    line-height: 16px;
}

.questionTitle {
    display: inline-block;
}

.icon {
    margin-right: $smallSpace;
    color: $otcGreen;

    &.error {
        color: $otcErrorColor;
    }
}

.moreInfo {
    width: 8px;
    cursor: pointer;
    padding-left: $xsmallSpace;
    color: $otcBlack;
}