@import '../../styles/variables.scss';

.container {
  border: 1px solid $otcGray;

  &::before {
    content: '';
    position: relative;
    display: block;
    height: 7px;
    background-color: $otcGreen;
  }

  &.error {
    &::before {
      background-color: $otcErrorColor;
    }
  }

  .content {
    position: relative;
    padding: $mediumSpace;
    line-height: 24px;

    &.collaspible {
      &.closed {
        max-height: 75px;
      }
    }

    .toggleBtn {
      position: absolute;
      top: $mediumSpace;
      right: $mediumSpace;
      cursor: pointer;
      transform: rotate(180deg);
      transition: all 250ms;

      &.closed {
        transform: none;
        transition: all 250ms;
      }
    }

    .title {
      margin-bottom: 0;
      flex: 1;
    }

    .secondary {
      margin-left: $mediumSpace;
    }

    .complete {
      color: $otcGreen;

      .icon {
        margin-right: $smallSpace
      }
    }

    .titleSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}