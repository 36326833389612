@import '../../styles/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $mediumSpace;
  min-height: 64px;
  border: solid 1px $otcGray;
  width: 100%;
  cursor: pointer;
  color: $otcBlack;

  &.complete {
    border-color: $otcGreen;
  }

  &.incomplete {
    border-color: $otcErrorColor;

    .subTitle {
      color: $otcErrorColor;

      &.highlight {
        background-color: $otcErrorColor;
      }
    }
  }

  .verticalAlign {
    display: flex;
    flex: 1;
    min-width: 0;
  }

  &.inactive {
    color: $otcGray;

    &:hover {
      box-shadow: none;
      cursor: default;
    }
  }

  .centerAlign {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: $smallSpace;

    &.incompleteIcon {
      color: $otcErrorColor;
    }
  }

  .iconCheck {
    margin-right: 4px;
    color: $otcGreen;
  }

  .remove {
    display: inline-flex;
    margin-left: $mediumSpace;
    font-size: 24px;
    padding: 4px;

    svg {
      height: 1em;
      width: 1em;
    }

    &:hover {
      background-color: #EEEEEE;
    }
  }

  &.add {
    border-style: dashed;
    border-color: $otcBlack;
    border-width: 2px;

    .addIcon {
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;
      margin-right: $mediumSpace;
    }

    .title {
      vertical-align: middle;
      font-weight: $fontWeightRegular;
      font-size: 20px;
      line-height: 25px;
    }

    &.small {
      .addIcon {
        font-size: 16px;
        margin-right: $mediumSpace;
      }

      .title {
        font-size: 16px;
      }
    }
  }

  &.medium {
    min-height: 48px;
    
    .title {
      font-size: 20px;
    }
  }

  &.small {
    min-height: 40px;

    .title {
      line-height: 18px;
    }
  }

  &:hover {
    @include btnHover;
  }

  &.empty {
    border-color: $otcLightGray;

    &:hover {
      box-shadow: none;
      cursor: default;
    }
    .title {
      font-size: 20px;
      color: $otcGray;
      font-weight: $fontWeightRegular;
    }
  }

  .title {
    font-size: 16px;
    font-weight: $fontWeightLight;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .subTitle {
    font-size: 16px;
    font-weight: $fontWeightBold;
    line-height: 16px;

    &.highlight {
      color: $otcWhite;
      background-color: $otcGreen;
      padding: 8px 16px;
    }
  }

  .complete {
    color: $otcGreen;
  }

  .dialog {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
  }
}