@import '../../styles/variables.scss';

.container {
  margin: 0 0 $smallSpace;
  font-size: 14px;
  color: $otcGray;
  font-weight: $fontWeightSemiBold;

  &.inline {
    display: flex;
    align-items: baseline;
    justify-content: center;

    .profileStatus {
      margin-left: $smallSpace
    }
  };

  .profileStatus {
    font-size: 12px;
    margin-top: $xsmallSpace;

    .verified {
      color: $otcGreen;
  
      .checkIcon {
        color: $iconGreen;
      }
    }
  
    .notVerified {
      color: $otcErrorColor;
  
      .xmarkIcon {
        color: $otcErrorColor;
      }
    }
  }
}