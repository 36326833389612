@import '../../../styles/variables.scss';

.section {
  position: relative;
}

.editBtn {
  position: absolute;
  top: -$smallSpace;
  right: 0;
  cursor: pointer;
}

.addressTitleContainer {
  display: flex;

  .addressCheckbox {
    padding-left: $smallSpace;
    flex: 1;
  }
}