@import '../../styles/global.scss';
@import '../../styles/variables.scss';

.container {
    margin-bottom: $largeSpace;

    .innerGrid {
        @include innerGrid;

        .verticalAlign {
            display: block;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .title {
        display: inline-block;
    }

    .incompleteTitle {
        @include incompleteTitle;
    }
}