@import '../../styles/variables.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: $mediumSpace;
  margin-bottom: $smallSpace;
  box-shadow: -1px 6px 7px 0 rgba(0,0,0,0.25);
  
  &.light {
    border: $otcGray 1px solid;
    background-color: $otcWhite;
  }
  
  &.dark {
    border: $otcGray 1px solid;
    background-color: $otcDarkGray;

    .title {
      color: $otcWhite;
      border-bottom: 1px solid $otcWhite;
    }

    .subTitle {
      color: $otcWhite;
    }
  }

  &.horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    min-height: 100%;
    width: 90%;

    .subTitle {
      margin: 0;
      padding-right: $mediumSpace;
    }
  }

  .controls {
    grid-template-columns: 6fr 6fr;
    gap: $smallSpace;
  }

  .title {
    border-bottom: 1px solid $otcGray;
    margin-bottom: $mediumSpace;
    padding-bottom: $smallSpace;
  }

  .subTitle {
    margin-bottom: $mediumSpace;
  }
}