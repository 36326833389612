@import '../../styles/variables.scss';

.container {
  a {
    margin-bottom: $smallSpace;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    margin: $mediumSpace 0;
    border-top: $otcGray solid 1px;
    border-bottom: none;
  }
}

.navItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 8px;
  font-size: 13px;
  font-weight: $fontWeightSemiBold;
  color: $otcBlack;
  background-color: $otcWhite;
  border: 1px solid $otcGray;
  cursor: pointer;

  &.static {
    border-color: $otcGreen;
    border-width: 2px;
    color: $otcGreen;
  }

  .completeIcon {
    margin-right: $smallSpace;
  }

  &.complete {
    border-color: $otcGreen;

    .completeIcon {
      color: $otcGreen;
    }

    .navIndex {
      border-color: $otcGreen;
    }
    
    &.active, &:hover {
      .completeIcon {
        color: $otcWhite;
      }
  
      .navIndex {
        border-color: $otcWhite;
      }
    }
  }

  &.active, &:hover {
    background-color: $otcGreen;
    color: $otcWhite;

    .navIndex {
      border-color: $otcWhite;
    }
  }

  .navTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    text-align: right;

    &.static {
      text-align: center;
    }
  }

  .navIndex {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $otcGray;
    font-size: 12px;
    width: 24px;
    height: 24px;
    margin-left: $smallSpace;
  }
}