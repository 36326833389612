@import '../../styles/variables.scss';

.children {
  padding-left: $XLargeSpace;

  &.disabled {
    opacity: .5;
  }
}

.error {
  display: block;
  margin: 0 4px 8px;
}

.icon {
  padding-right: $xsmallSpace;

  &.green {
    color: $otcGreen;
  }

  &.moreInfo {
    width: $moreInfoIconSize;
    cursor: pointer;
    padding-left: $xsmallSpace;
  }
}