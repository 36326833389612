@import '../../styles/variables.scss';

.container {
  .list {
    > div {
      position: relative;
      display: flex;
      margin-bottom: $smallSpace;
    }
  }

  .title {
    display: inline-block;
  }

  .incomplete {
    @include incompleteTitle;
  }

  .icon {
    padding-right: $xsmallSpace;

    &.moreInfo {
      width: $moreInfoIconSize;
      cursor: pointer;
      padding-left: $xsmallSpace;
    }
  }
}