@import '../../styles/variables.scss';

.container {
    font-size: 12px;
    margin-top: 5px;
    color: $otcGray;
    text-align: right;

    .error {
        color: $otcErrorColor;
    }

    .errorMsg {
        margin-right: 5px;
    }
}