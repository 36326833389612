@import "../../styles/otc-variables.scss";

$infoTextFontSize: 0.9em;

.container {
  display: flex;
  flex: 1 1 0%;

  @include sm {
    display: block;
  }

  .main {
    display: flex;
    flex: 1 1 100%;
    @include otcBottomBorder;

    @include sm {
      display: block;
      padding-bottom: 0.5em;
    }
  }

  .companyHeader {
    width: 50%;

    @include sm {
      width: 100%;
    }
  }

  .bid {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include sm {
      justify-content: start;
    }
  }

  .companyBid {
    width: 50%;

    @include sm {
      width: 100%;
    }
  }

  .companyName {
    font-size: 1.3em;
    margin: $smallSpace 0;

    &:empty::after {
      content: " ";
      white-space: pre;
    }
  }

  h2 {
    @include h2;
    margin: 0;
  }

  p {
    margin: $smallSpace 0 0;
  }

  .subHeader {
    @include caption;
    font-weight: 300;
    position: relative;
    line-height: 1.2em;

    &:empty::after {
      content: " ";
      white-space: pre;
    }

    * {
      line-height: 1.2em;
    }
  }

  .evo {
    @include h2;
    margin-left: 0.2em;

    p {
      font-size: 0.36em;
      line-height: 1.25em;
      margin: 0;
    }
  }

  .iconArrow {
    font-size: 1.25em;
    margin-right: -0.2em;
    width: 1.25em;
  }

  .positive {
    svg,
    p {
      color: $otcGreen;
    }

    svg {
      margin-top: -0.02em;
    }
  }

  .negative {
    svg,
    p {
      color: $otcErrorColor;
    }
  }

  .info {
    flex: 0 0 300px;
    flex-direction: column;
    max-width: 300px;

    @include sm {
      margin-top: $mediumSpace;
      margin-right: 0;
      max-width: none;
    }

    &.left {
      margin-right: 100px;

      @include sm {
        margin-right: 0;
      }
    }

    &.right {
      margin-left: 50px;

      @media (min-width: $max-width) {
        margin-left: 100px;
      }

      @include sm {
        margin-left: 0;
      }
    }

    @include otcBottomBorder;
  }

  .profile {
    .tierText {
      font-size: $infoTextFontSize;
      padding-bottom: 0.5em;

      @include sm {
        border-top: 1.5px solid $otcLightGray;
        padding-top: 0.5em;
        padding-bottom: 0em;
      }
    }

    .informationOutline {
      padding-top: 0;
      border-top: none;
      font-size: 1em !important;
    }

    .infoText {
      font-size: $infoTextFontSize;
      display: block;
      width: 100%;
      text-decoration: none;
      line-height: 1.6em;

      @include sm {
        margin-bottom: 0.5em;
      }
    }

    img {
      height: 1.7rem;
      margin-bottom: 0.2em;

      &.award {
        height: 4.1rem;
      }
    }
  }
}

.noChange {
  svg, p {
    color: $otcGray;
    margin-left: .2em;
  }
}

@include sm {
  .ask {
    padding: 0.3em 0 0.2em 0 !important;
  }

  .bid {
    padding-top: 0.5em;
    border-top: $dividerGrey;
    margin-top: 1em;

    @include sm {
      margin-top: 0.5em;
      padding-top: 1em;
    }
  }
}

@include md-lg {
  .ask {
    font-size: 0.9em;
    line-height: 1.8em;
    position: relative;
    top: -0.5em;
  }

  .companyBid {
    text-align: right;
  }
}
