@import '../../styles/variables.scss';

.container {
  position: relative;
  margin-bottom: $largeSpace;
  border: 1px solid $otcGray;

  .inactive {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255, .8);
    z-index: 1;
  }

  .hide {
    visibility: hidden;
  }

  .titleBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $mediumSpace;
  }

  .checkIcon {
    color: $iconGreen;
  }

  .xmarkIcon {
    color: $otcErrorColor;
  }

  .errorMsg {
    display: inline-block;
    font-weight: $fontWeightBold;
    color: $otcErrorColor;
    margin-left: $smallSpace;
  }

  .verifiedStatus {
    display: flex;
    align-items: center;
    padding-left: $mediumSpace;

    .status {
      font-size: 14px;
      font-weight: $fontWeightBold;
      padding-left: $smallSpace;
    }
  }

  .typeContainer {
    display: flex;
    align-items: center;

    .typeIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 112px;
      background-color: $otcGray;

      &.green {
        background-color: $otcGreen;
      }

      &.orange {
        background-color: $otcOrange;
      }

      &.pink {
        background-color: $otcPink;
      }
    }

    .type {
      font-size: 14px;
      margin-left: $mediumSpace;
    }
  }

  .statusContainer {
    position: absolute;
    top: 12px;
    right: $mediumSpace;

    .status {
      text-align: right;
      font-size: 14px;
      font-weight: $fontWeightExtraBold;
    }

    .modifiedBy {
      margin-top: $smallSpace;
      font-weight: $fontWeightLight;
      font-size: 14px;
    }
  }

  .info {
    padding: $smallSpace $mediumSpace $mediumSpace;

    .name {
      font-size: 20px;
      font-weight: $fontWeightExtraBold;
      margin-bottom: $mediumSpace;
      margin-top: $smallSpace;
      word-break: break-all;
    }

    .cancel {
      display: inline-block;
      @include link;
      font-size: 14px;
    }

    .reopen {
      display: inline-block;
      @include link;
      font-size: 14px;
      margin-left: 8px;
    }

    .historyLink {
      cursor: pointer;
      float: right;
      font-size: 14px;

      .chevron {
        transition: transform 250ms;
        margin-left: $xsmallSpace;
        -webkit-font-smoothing: antialiased;

        &.open {
          transform: rotate(-180deg);
        }
      }
    }

    .cta {
      text-align: right;
    }
  }

  .flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .bottomRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: $mediumSpace;
    padding-right: $mediumSpace;
  }

  .divider {
    display: block;
    height: 1px;
    background-color: $otcGray;
    opacity: 0;
    transition: all 250ms;

    &.open {
      margin-top: $mediumSpace;
      border-width: 1px;
      opacity: 1;
      transition: all 250ms;
    }
  }

  .historyTable {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 250ms;

    &.open {
      margin-top: $smallSpace;
      max-height: 100px;
      opacity: 1;
      transition: all 250ms;
    }
  }

  .complete {
    font-size: 14px;
    color: $otcGreen;
    font-weight: $fontWeightLight;
    flex: 0 0 100%;
    align-self: flex-start;
  }

  .subText {
    color: $otcBlack;
  }

  .dialogContainer {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;

    .dialog {
      width: 75%;
      height: 100%;
    }
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  grid-gap: $mediumSpace;
  align-items: end;
}