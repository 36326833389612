@import '../../../styles/variables.scss';

.container {
  position: relative;
}

.editBtn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}