@import '../../styles/variables.scss';

.container {
  position: sticky;
  top: 96px;

  .item {
    display: flex;
    border: 1px solid $otcBlack;
    border-radius: 8px;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .logo {
      cursor: default;
      max-width: 40px;
    }

    &.application {
      border: none;
      cursor: default;
      padding: $xsmallSpace;
      background-color: $otcGray;

      &:hover {
        box-shadow: none;
      }

      &.green {
        background-color: $otcGreen;
      }

      &.orange {
        background-color: $otcOrange;
      }

      &.pink {
        background-color: $otcPink;
      }
    }

    &:not(:last-child) {
      margin-bottom: $smallSpace;
    }

    &:hover {
      @include btnHover;
    }

    &.inactive {
      opacity: .5;
      cursor: not-allowed;
    }

    &.errorActive {
      border-color: $otcErrorColor;
      border-width: 3px;

      svg {
        color: $otcErrorColor;
      }
    }
  }  
}
