@import '../../styles/variables.scss';

.container {
  padding: $mediumSpace;
  border: $otcGray 1px solid;
  margin-bottom: $mediumSpace;
  background-color: $otcWhite;

  &.notValid {
    @include notValid;
  }

  &.secondaryContainer {
    border: none;
    padding: 0;
    margin-bottom: $largeSpace;

    .title {
      @include h1;
    }
  }

  &.nestedContainer {
    border: none;
    border-top: $otcGray 1px solid;
    padding: 0;
    padding-top: $mediumSpace;
    margin-top: $mediumSpace;
    margin-bottom: 0;
  }

  .title {
    display: inline-block;
  }

  .controls {
    div {
      &:first-child {
        margin-right: $mediumSpace;
      }
    }
  }

  .options {
    > div {
      margin-bottom: $smallSpace;
    }
  }
}