.container {
    .lock {
        position: absolute;
        width: 100%;
        height: 100%;
        background: white;
        opacity: .25;
        z-index: 999;
    }
}

.loading {
    position: fixed;
    left: 50%;
    top: 50%;
}