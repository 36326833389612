@import '../../styles/variables.scss';

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: $mediumSpace;
  background-color: $otcGreen;
  color: $otcWhite;
  width: 512px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);

  &.error {
    background-color: $otcErrorColor;
  }

  .close {
    cursor: pointer;
  }
}