@import '../../../styles/variables.scss';

.icon {
    display: inline-block;
    color: $otcGreen;
}

.labelTitle {
    display: inline-block;
    padding-left: $smallSpace;
}