@import '../../styles/variables.scss';

.container {
  display: flex;
  padding-bottom: $mediumSpace;
  border-bottom: $otcGray 1px solid;
  margin-bottom: $largeSpace;

  & > div:not(:last-child) {
    margin-right: $smallSpace;
  }
}