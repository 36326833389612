@import "../../styles/global.scss";

.link {
  display: inline-block;
  font-family: $otcFontPrimary;
  color: $otcGreen;
  text-decoration: none;
  font-weight: $fontWeightSemiBold;
  cursor: pointer;
}

.small {
  font-size: 11px;
}

.active {
  &:hover {
    text-decoration: underline;
  }
}

.inactive {
  color: $otcGray;
  cursor: default;
}