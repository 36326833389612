@import "../../styles/variables.scss";

@mixin disabledPrimary {
  opacity: .5;
  cursor: default;
}

@mixin disabledSecondary {
  opacity: .5;
  cursor: default;
}

.button {
  display: inline-block;

  &.readOnly {
    button {
      cursor: not-allowed;
    }
  }

  &.fullWidth {
    width: 100%;

    button {
      width: 100%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: solid;
    font-weight: bold;
    height: 40px;
    padding: 0 25px;
    cursor: pointer;
    font-size: .9375rem;
    outline: none;
    transition: background-color 0.2s ease-in-out;
    border-radius: 8px;
    text-decoration: none !important;
    letter-spacing: .035em;
    font-family: $otcFontPrimary;

    &:hover {
      @include btnHover;
    }

    &.small {
      padding: 0 15px;
      height: 32px;
    }

    &.extraSmall {
      height: 30px;
      min-width: 40px;
      padding: 0 10px;
      font-size: 11px;
    }

    &.large {
      height: 48px
    }

    &.black {
      border-color: $otcBlack;

      &.primary {
        color: $otcWhite;
        background: $otcBlack;

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcBlack;
        background: $otcWhite;

        &:disabled {
          @include disabledSecondary();
        }
      }
    }

    &.green {
      border-color: $otcGreen;

      &.primary {
        color: $otcWhite;
        background: $otcGreen;

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcGreen;
        background: $otcWhite;

        &:disabled {
          @include disabledSecondary();
        }
      }
    }

    &.pink {
      border-color: $otcPink;

      &.primary {
        color: $otcWhite;
        background: $otcPink;

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcPink;
        background: $otcWhite;

        &:disabled {
          @include disabledSecondary();
        }
      }
    }

    &.orange {
      border-color: $otcOrange;

      &.primary {
        color: $otcWhite;
        background: $otcOrange;

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcOrange;
        background: $otcWhite;

        &:disabled {
          @include disabledSecondary();
        }
      }
    }
    
    &.gray {
      border-color: $otcDarkGray;

      &.primary {
        color: $otcWhite;
        background: $otcDarkGray;

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcDarkGray;
        background: $otcWhite;

        &:disabled {
          @include disabledSecondary();
        }
      }
    }

    &.red {
      border-color: $otcErrorColor;

      &.primary {
        color: $otcWhite;
        background: $otcErrorColor;

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcErrorColor;
        background: $otcWhite;

        &:disabled {
          @include disabledSecondary();
        }
      }
    }
  }
}

.loader {
  font-size: 4px;
  margin-left: $mediumSpace;
  text-indent: -9999em;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background: #000000;
  background: -moz-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
  background: -webkit-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
  background: -o-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
  background: -ms-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
  background: linear-gradient(to right, #000000 10%, rgba(0,0,0, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #000000;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #e4e4e4;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}