@import '../../styles/global.scss';
@import '../../styles/variables.scss';

.container {
  .innerGrid {
    @include innerGrid
  }

  .warningIcon {
    position: relative;
    top: 2px;
    float: left;
    color: $otcErrorColor;
    margin-right: $smallSpace
  }

  .warningText {
    font-weight: $fontWeightBold;
  }
}