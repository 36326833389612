@import '../../styles/variables.scss';

.container {
  h3 {
    @include h3;
    margin-bottom: $smallSpace;
  }

  .other {
    margin-top: $mediumSpace;
  }
}