@import '../../../styles/global.scss';
@import '../../../styles/variables.scss';

.icon {
    display: inline-block;
    color: $otcGreen;
}

.labelTitle {
    display: inline-block;
    padding-left: $smallSpace;
}

.entityList {
    > div {
        margin-bottom: $smallSpace;
    }
}

.hideTitles {
    visibility: hidden;
}

.inline {
    display: inline-block;
}

.innerGrid {
    @include innerGrid;
}

.incomplete {
    @include incompleteTitle;
}