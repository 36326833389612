@import '../../styles/variables.scss';

.container {
    margin-bottom: $largeSpace;

    .header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .title {
            display: flex;
            align-items: baseline;

            .icon {
                color: $otcGreen;
                margin-right: $smallSpace;
        
                &.error {
                    color: $otcErrorColor;
                }
            }
        }
    }

    .link {
        font-size: 12px;
    }

    .section {
        margin-bottom: $largeSpace;

        .sectionHeader {
            font-size: 16px;
            font-weight: $fontWeightBold;
            border-bottom: 1px solid $otcGreen;
            padding-bottom: $xsmallSpace;
    
            .addIcon {
                color: $otcGreen;
                margin-right: $smallSpace;
            }
        
            .deleteIcon {
                color: $otcErrorColor;
                margin-right: $smallSpace;
            }
        }
    }

    .noChange {
        color: $otcGreen;
        font-size: 11px;
        padding-left: 25px;
    }
}