@import '../../styles/otc-variables.scss';

.descIcon {
    color: $iconGreen;
    vertical-align: text-top;
}

.container {
    font-family: $otcFontPrimary, sans-serif;
    line-height: 1.5em;
    
    h1 {
        @include h1;
        margin-top: 0;
        line-height: 1em;
    }
    h2 {
        @include h2;
    }
    h3 {
        @include h3;
    }

    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: $otcDarkGray;

    .compHeader {
        max-width: $max-width;
    }

    main {
        flex: 1;
        max-width: $max-width;
        width: 100%;
        margin: $XLargeSpace auto $XXLargeSpace;
        padding: 0 $mediumSpace;
    }

  
}

.logoMetaInfo{
    font-weight: $fontWeightBold;

}

.dialogControls {
    text-align: right;
  }

  .saveDialogControls{
    margin-top: $XLargeSpace;
    display: flex;
    align-items: center;

  }

  .deleteDialogControls{
    display: flex;
    align-items: center;
    justify-content: right;
  }
  
  .confirmMsg {
    color: $otcGreen;
    font-size: 20px;
    font-weight: $fontWeightBold;
    margin-bottom: $mediumSpace;
  }

  .deleteConfirm{
    margin-left: 10px;
  }

  .deleteSuccessIcon{
    margin-right: 10px;
  }



  .infoContainer {
    display: flex;
    flex: 1 1 0%;
  
    @include sm {
      display: block;
    }  

  
 
    p {
      margin: $smallSpace 0 0;
    }
  
    .subHeader {
      @include caption;
      font-weight: 300;
      position: relative;
      line-height: 1.2em;
  
      &:empty::after {
        content: " ";
        white-space: pre;
      }
  
      * {
        line-height: 1.2em;
      }
    }

    .info {
        flex: 0 0 300px;
        flex-direction: column;
        max-width: 300px;
    
        @include sm {
          margin-top: $mediumSpace;
          margin-right: 0;
          max-width: none;
        }
    
        &.left {
          margin-right: 100px;
    
          @include sm {
            margin-right: 0;
          }
        }
    
        &.right {
          margin-left: 50px;
    
          @media (min-width: $max-width) {
            margin-left: 100px;
          }
    
          @include sm {
            margin-left: 0;
          }
        }
    
      }
    

}
  
.links{
    color: black;
    text-decoration: underline;
}

.previewLogo{
  max-width: 12em;
  margin: 24px 0 0.8em;

}
.noLogo{
    margin: 20px 0px;
    max-width: 12em;
    padding: 32px;
    height: 40px;
    text-transform: uppercase;
    background-color: $otcLightGray;
    font-weight: $fontWeightBold;

    // make it middle
    display: flex;
    justify-content: center;
    align-items: center;    

}

.companyLogo {
    img {
      max-width: 12em;
      margin: .5em 0 .8em 0;
    }
  }

  .changeLogo{
    margin-left: 20px;
  }

  .loaderContainer{
    margin: 0 10px;
  }

  .loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid $otcGray;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  .deleteModalContainer{
    margin: 20px;
  }

  .metaInfo{
    margin: 10px 0;
  }

  .ackReject{
    margin: 10px 0;
    display: flex;
    align-items: center;

  }